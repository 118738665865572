/**
 * grid.scss
 * grid mixins for for scss
 */

// this function parses arbitrary number of arguments passed as the columns.
@function parseArbitraryArgumentsColumns($columns...) {
  $return_columns: '';
  $control_line: 0;

  @each $column in $columns {
    @if $control_line == 0 {
      $return_columns: [column-line-start] #{$column};
    }

    @else {
      $column_line_name: column-line-#{$control_line};
      $return_columns: $return_columns #{'[column-line-' + $control_line + ']'} #{$column};
    }

    $control_line: $control_line + 1;
  }

  @return $return_columns [column-line-end];
}

@function parseArbitraryArgumentsRow($columns...) {
  $return_columns: '';
  $control_line: 0;

  @each $column in $columns {
    @if $control_line == 0 {
      $return_columns: [row-line-start] #{$column};
    }

    @else {
      $column_line_name: column-line-#{$control_line};
      $return_columns: $return_columns #{'[row-line-' + $control_line + ']'} #{$column};
    }

    $control_line: $control_line + 1;
  }

  @return $return_columns [row-line-end];
}

@mixin grid() {
  display: grid;
  @content;
}

@mixin columns($columns...) {
  grid-template-columns: parseArbitraryArgumentsColumns($columns...);
}

@mixin rows($columns...) {
  grid-template-rows: parseArbitraryArgumentsRow($columns...);
}
