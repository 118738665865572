@import "../../scss/media-queries";
@import "../../scss/mixins/grid";
@import "../../scss/mixins/b";
@import "../../scss/fonts";


div.About {
  width: 85%;
  margin: 120px auto 40px;

  div {
    text-align: center;

    img {
      width: 38%;
    }
  }

  div.about {
    margin-top: 85px;
    padding: 39.9px 38.6px 50.5px 52.9px;
    border-radius: 5px;
    background-color: #ffffff;

    h1 {
      @include LatoRegular();
      font-size: 32px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: center;
      color: #32325d;
    }

    p {
      @include RalewayRegular();
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #525f7f;
    }
  }

}
