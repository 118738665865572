@import "../../scss/media-queries";
@import "../../scss/mixins/grid";
@import "../../scss/mixins/b";
@import "../../scss/fonts";

body {
  nav {
    li {
      @apply cursor-pointer;
    }
    a {
      &.active {
        font-family: "Lato Bold", Serif, Georgia, serif;
      }
    }
  }
}
