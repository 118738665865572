html {
  scroll-behavior: smooth;
}
.shadow-custom {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25),
  -5px 0 20px rgba(0, 0, 0, 0.25);
}

.shadow-custom-minor {
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25),
  -3px -1px 3px rgba(0, 0, 0, 0.25);
}

.hand {
  cursor: pointer;
}
