.scroll-to-top{
  position: fixed;
  width: 100%;
  display: inline-block;
  flex-shrink: 0;
  right: 40%;
  bottom: 0;
  z-index: 999;
}
.scroll-content{
  position: absolute;
  border-radius: 9999px;
  color: #ffffff;
  background-color: #00b0ff;
  cursor: pointer;
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  right: 15px;
  bottom: 2rem;

  img {
    width: 40px;
  }

  > span {
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1.75rem;
  }
}
