@import "../../scss/media-queries";
@import "../../scss/mixins/grid";
@import "../../scss/mixins/b";
@import "../../scss/fonts";

#app-jobs {
  color: #ffffff;
  min-height: 100vh;
  max-width: calc(100vw - b(30));
  padding: 0 0 0 b(30) !important;

  header {
    text-align: center;
    padding-top: b(30);

    img {
      width: 380px;
      height: 116px;
    }
  }

  @include mediaMobileScreen() {
    header img {
      width: 80%;
      height: 30%;
    }
  }

  > div {
    @include grid() {
      @include columns(b(300), 0.8fr, b(300));
      justify-content: space-between;
      margin: b(50) auto;

      &.text-image-both {
        img.map-image {
          width: 230px;
          height: 145px;
          align-self: center;
        }
      }

      &.text-center-image-last {
        img {
          width: 130px;
          height: 140px;
          align-self: end;
        }
      }

      &.image-first-text-center {
        text-align: center;
        margin-bottom: b(80) !important;

        img {
          width: 130px;
          height: 130px;
          align-self: center;
          justify-self: end;
        }

         p {
           margin-bottom: b(80) !important;
         }

        a {
          width: 174px;
          height: 48px;
          border-radius: 6px;
          margin-top: b(20);
          padding: 9px 51px 13px 52px;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          background-color: #33ce8d;
          color: #ffffff;
          text-decoration: none;
          cursor: pointer;
        }
      }

      &.lone-image-center {
        text-align: center;
        padding-bottom: b(30);
        margin-bottom: 0 !important;

        img{
          width: 310px;
          height: 270px;
          justify-self: center;
          grid-column: column-line-1 / column-line-2;
        }
      }

      h1 {
        text-align: center;
        @include Exo2Bold();
      }

      p {
        text-align: center;
        @include Exo2Regular();
      }

      div.social-images {
        align-self: center;
        img {
          width: 45.7px;
          height: 40.2px;
        }

        article:nth-child(1), article:nth-child(3) {
          margin: 0;
          padding-left: b(60);
        }

        article:nth-child(3) {
          img {
            height: 50px;
          }
        }

      }
    }

    @include mediaLaptopScreen() {
      @include columns(b(150), 0.9fr, b(150));
      align-items: center;

      &.text-image-both {
        img.map-image {
          width: 130px;
          height: 90px;
          align-self: center;
        }
      }

      div.social-images {
        align-self: center;
        img {
          width: 30px;
          height: 25px;
        }

        article:nth-child(1), article:nth-child(3) {
          margin: 0;
          padding-left: b(40);
        }

      }

      &.lone-image-center {
        text-align: center;
        padding-bottom: b(30);
        margin-bottom: 0 !important;

        img{
          width: 230px;
          height: 170px;
          justify-self: center;
          grid-column: column-line-1 / column-line-2;
        }
      }
    }

    @include mediaMobileScreen() {
      @include columns(0, 0.8fr, 0);

      > div {
        grid-column: column-line-1 / column-line-2;
      }

      img {
        display: none;
      }
    }

    @include mediaTabletScreen() {
      @include columns(0, 0.8fr, 0);

      > div {
        grid-column: column-line-1 / column-line-2;
      }

      img {
        display: none;
      }
    }
  }
}
