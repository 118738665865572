//@import "../../scss/media-queries";
//@import "../../scss/mixins/grid";
//@import "../../scss/mixins/b";
//@import "../../scss/fonts";
//
//
//div.contact {
//  width: 85%;
//  margin: 120px auto 40px;
//
//  > div.contact-intro {
//    @include grid() {
//      @include columns(1fr, 1fr);
//      align-items: start;
//    }
//
//    > div:first-child {
//      width: 50%;
//
//      h1 {
//        @include LatoRegular();
//        font-size: 32px;
//        font-weight: normal;
//        font-stretch: normal;
//        font-style: normal;
//        line-height: 1.38;
//        letter-spacing: normal;
//        text-align: left;
//        color: #11141a;
//      }
//
//      p {
//        @include RalewayRegular();
//        font-size: 24px;
//        font-weight: normal;
//        font-stretch: normal;
//        font-style: normal;
//        line-height: 1.33;
//        letter-spacing: normal;
//        text-align: left;
//        color: #11141a;
//      }
//    }
//
//    > div:last-child {
//      text-align: right;
//      img {
//        width: 80% !important;
//      }
//    }
//
//    @include mediaMobileScreen() {
//      @include grid() {
//        @include columns(1fr);
//        align-items: start;
//      }
//
//      > div:first-child {
//        width: 100%;
//      }
//
//      > div:last-child {
//        display: none;
//      }
//    }
//  }
//
//  div.form {
//    padding: 40px 28px;
//    border-radius: 5px;
//    background-color: #ffffff;
//    margin-top: 85px;
//
//    > div {
//      margin: 0 0 20px;
//
//      input, textarea {
//        width: calc(100% - 30px);
//        padding: 16px 10.5px 15px 16.5px;
//        border-radius: 4px;
//        border: solid 2px #bbc5d5;
//
//        @include LatoRegular();
//        font-size: 14px;
//        font-weight: normal;
//        font-stretch: normal;
//        font-style: normal;
//        letter-spacing: 0.13px;
//        text-align: left;
//
//        &::placeholder {
//          color: #bbc5d5;
//        }
//
//        + span {
//          color: red;
//          @include LatoRegular();
//        }
//      }
//
//      textarea {
//        height: 236px;
//        resize: none;
//      }
//
//      &:last-child {
//        margin-top: 20px;
//        text-align: center;
//
//        button {
//          padding: 10px 78.3px 11px 79.7px;
//          border-radius: 2px;
//          background-color: #2aaae1;
//          border: 2px solid #2aaae1;
//          cursor: pointer;
//          outline: none;
//
//          @include LatoRegular();
//          font-size: 17px;
//          font-weight: normal;
//          font-stretch: normal;
//          font-style: normal;
//          line-height: 1.24;
//          letter-spacing: normal;
//          text-align: center;
//          color: #ffffff;
//        }
//      }
//    }
//
//  }
//
//  div.post-status {
//    color: red;
//
//    &.status-200, &.status-201 {
//      color: #0dab93 !important;
//    }
//  }
//}
//
div.g-recaptcha {
  width: fit-content;
  margin: 0 auto;
}
