/**
 * @file Font declarations and mixins.
 */

@font-face {
  font-family: 'Exo2 Black';
  src: url('../Font/Exo2-Black.otf') format('opentype');
}
@mixin Exo2Black() {
  font-family: 'Exo2 Black', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 Black Italic';
  src: url('../Font/Exo2-BlackItalic.otf') format('opentype');
}
@mixin Exo2BlackItalic() {
  font-family: 'Exo2 Black Italic', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 Bold';
  src: url('../Font/Exo2-Bold.otf') format('opentype');
}
@mixin Exo2Bold() {
  font-family: 'Exo2 Bold', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 Bold Italic';
  src: url('../Font/Exo2-BoldItalic.otf') format('opentype');
}
@mixin Exo2BoldItalic() {
  font-family: 'Exo2 Bold Italic', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 ExtraBold';
  src: url('../Font/Exo2-ExtraBold.otf') format('opentype');
}
@mixin Exo2ExtraBold() {
  font-family: 'Exo2 ExtraBold', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 ExtraBold Italic';
  src: url('../Font/Exo2-ExtraBoldItalic.otf') format('opentype');
}
@mixin Exo2ExtraBoldItalic() {
  font-family: 'Exo2 ExtraBold Italic', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 ExtraLight';
  src: url('../Font/Exo2-ExtraLight.otf') format('opentype');
}
@mixin Exo2ExtraLight() {
  font-family: 'Exo2 ExtraLight', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 ExtraLight Italic';
  src: url('../Font/Exo2-ExtraLightItalic.otf') format('opentype');
}
@mixin Exo2ExtraLightItalic() {
  font-family: 'Exo2 ExtraLight Italic', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 Italic';
  src: url('../Font/Exo2-Italic.otf') format('opentype');
}
@mixin Exo2Italic() {
  font-family: 'Exo2 Italic', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 Regular';
  src: url('../Font/Exo2-Regular.otf') format('opentype');
}
@mixin Exo2Regular() {
  font-family: 'Exo2 Regular', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 Light';
  src: url('../Font/Exo2-Light.otf') format('opentype');
}
@mixin Exo2Light() {
  font-family: 'Exo2 Light', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 LightItalic';
  src: url('../Font/Exo2-LightItalic.otf') format('opentype');
}
@mixin Exo2LightItalic() {
  font-family: 'Exo2 LightItalic', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 Medium';
  src: url('../Font/Exo2-Medium.otf') format('opentype');
}
@mixin Exo2Medium() {
  font-family: 'Exo2 Medium', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 MediumItalic';
  src: url('../Font/Exo2-MediumItalic.otf') format('opentype');
}
@mixin Exo2MediumItalic() {
  font-family: 'Exo2 MediumItalic', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 SemiBold';
  src: url('../Font/Exo2-SemiBold.otf') format('opentype');
}
@mixin Exo2SemiBold() {
  font-family: 'Exo2 SemiBold', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 SemiBoldItalic';
  src: url('../Font/Exo2-SemiBoldItalic.otf') format('opentype');
}
@mixin Exo2SemiBoldItalic() {
  font-family: 'Exo2 SemiBoldItalic', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 Thin';
  src: url('../Font/Exo2-Thin.otf') format('opentype');
}
@mixin Exo2Thin() {
  font-family: 'Exo2 Thin', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 ThinItalic';
  src: url('../Font/Exo2-ThinItalic.otf') format('opentype');
}
@mixin ExoThinItalic() {
  font-family: 'Exo2 ThinItalic', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Raleway Bold';
  src: url('../Font/Raleway-Bold.ttf') format('truetype');
}
@mixin RalewayBold() {
  font-family: 'Raleway Bold', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Raleway Medium';
  src: url('../Font/Raleway-Medium.ttf') format('truetype');
}
@mixin RalewayMedium() {
  font-family: 'Raleway Medium', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Raleway Regular';
  src: url('../Font/Raleway-Regular.ttf') format('truetype');
}
@mixin RalewayRegular() {
  font-family: 'Raleway Regular', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Raleway Light';
  src: url('../Font/Raleway-Light.ttf') format('truetype');
}
@mixin RalewayLight() {
  font-family: 'Raleway Light', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Raleway Black';
  src: url('../Font/Raleway-Black.ttf') format('truetype');
}
@mixin RalewayBlack() {
  font-family: 'Raleway Black', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Lato Bold';
  src: url('../Font/Lato-Bold.ttf') format('truetype');
}
@mixin LatoBold() {
  font-family: 'Lato Bold', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Lato Light';
  src: url('../Font/Lato-Light.ttf') format('truetype');
}
@mixin LatoLight() {
  font-family: 'Lato Light', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Lato Regular';
  src: url('../Font/Lato-Regular.ttf') format('truetype');
}
@mixin LatoRegular() {
  font-family: 'Lato Regular', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Lato Black';
  src: url('../Font/Lato-Black.ttf') format('truetype');
}
@mixin LatoBlack() {
  font-family: 'Lato Black', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}
