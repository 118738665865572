
//320px — 480px: Mobile devices
//481px — 768px: iPads, Tablets
//769px — 1024px: Small screens, laptops
//1025px — 1200px: Desktops, large screens
//1201px and more —  Extra large screens, TV

// @media queries
$mainBreakpoints: (
  'mobile': '900/1098',
  'landingWideScreen': '1920/880',
);

@mixin landingWideScreen() {
  @include mediaMinAspectRatioList($mainBreakpoints, 'landingWideScreen') {
    @content;
  }
}

@mixin mediaMaxAspectRatio($ratio) {
  @media screen and (max-aspect-ratio: map-get($ratio)) {
    @content;
  }
}

@mixin mediaMaxAspectRatioList($breakpoints, $_key) {
  @media screen and (max-aspect-ratio: map-get($breakpoints, $_key)) {
    @content;
  }
}

@mixin mediaMinAspectRatio($ratio) {
  @media screen and (min-aspect-ratio: $ratio) {
    @content;
  }
}

@mixin mediaMinAspectRatioList($breakpoints, $_key) {
  @media screen and (min-aspect-ratio: map-get($breakpoints, $_key)) {
    @content;
  }
}

@mixin mediaMobile() {
  @include mediaMaxAspectRatioList($mainBreakpoints, 'mobile') {
    @content;
  }
}

@mixin mediaNotMobile() {
  @include mediaMinAspectRatioList($mainBreakpoints, 'mobile') {
    @content;
  }
}

@mixin widthSpecifications($minWidth, $maxWidth) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content
  }
}

@mixin mediaMobileScreen() {
  @include widthSpecifications(1px, 480px) {
    @content
  }
}

@mixin mediaTabletScreen() {
  @include widthSpecifications(418px, 768px) {
    @content
  }
}

@mixin mediaLaptopScreen() {
  @include widthSpecifications(769px, 1024px) {
    @content
  }
}

@mixin mediaDesktopScreen() {
  @include widthSpecifications(1025px, 1200px) {
    @content
  }
}

@mixin mediaHugeScreen() {
  @media screen and (min-width: 1201px) {
    @content
  }
}
