@import "../../scss/media-queries";
@import "../../scss/mixins/grid";
@import "../../scss/mixins/b";
@import "../../scss/fonts";

#under-construction {
  min-height: 100vh;
  color: #ffffff;

  @include grid() {
    @include rows(0.5fr, 1fr, 1fr);
    align-content: space-between;
    grid-row-gap: 4em;

    header {
      text-align: center;
      padding-top: b(30);

      img {
        width: 380px;
        height: 116px;
      }
    }
  }

  @include mediaMobileScreen() {
    header img {
      width: 80%;
      height: 80%;
    }

    div.construction-image {
      width: 100% !important;
    }
  }

  div {
    text-align: center;

    img {
      width: b(300);
      height: b(270);
    }

    @include mediaMobileScreen() {
      width: 80%;
      height: 50%;
    }
  }

  footer {
    text-align: center;

    @include mediaMobileScreen() {
      width: 90%;
      font-size: 0.9em;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    h1 {
      @include Exo2Black();
      font-size: b(30);
    }

    h2 {
      @include Exo2Bold();
      font-size: b(25);
    }

    a {
      @include Exo2Regular();
      width: 174px;
      height: 48px;
      border-radius: 6px;
      margin-top: b(20);
      padding: 9px 51px 13px 52px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #33ce8d;
      color: #ffffff;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
